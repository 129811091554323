<!-- Navbar -->
<!--Main layout-->
<main class="back-image">
    <div class="container">

        <hr class="my-5">
        <!--Section: Main features & Quick Start-->
        <section>
            <!--Grid row-->
            <div class="row wow fadeIn">

                <!--Grid column-->
                <div class="col-lg-6 col-md-12 px-4">

                    <!--First row-->
                    <div class="row">
                        <div class="col-1 mr-3">
                            <i class="fas fa-code fa-2x indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <h5 class="feature-title">WELCOME TO SHAZ</h5>
                            <p class="grey-text">Shaz has been manufacturing and exporting leather goods, We have modern
                                factories and modern technology in Kolkata, India since 2010.
                            </p>
                        </div>
                    </div>
                    <!--/First row-->

                    <div style="height:30px"></div>

                    <!--Second row-->
                    <div class="row">
                        <div class="col-1 mr-3">
                            <i class="fas fa-book fa-2x blue-text"></i>
                        </div>
                        <div class="col-10">
                            <h5 class="feature-title">BEST QUALITY OF PRODUCTS</h5>
                            <p class="grey-text">We are making best quality of leather & other accessories at
                                most competitive prices within India and from the other parts of the world to give
                                challenges price for customers.
                            </p>
                        </div>
                    </div>
                    <!--/Second row-->

                    <div style="height:30px"></div>

                    <!--Third row-->
                    <div class="row">
                        <div class="col-1 mr-3">
                            <i class="fas fa-graduation-cap fa-2x cyan-text"></i>
                        </div>
                        <div class="col-10">
                            <h5 class="feature-title">LEATHER INNOVATIVE GOODS</h5>
                            <p class="grey-text">We are manufacturing coolest innovative leather goods. We have a lots
                                of products in various leathers such as cow, buffalo, goat & sheep & different designs &
                                models to suite every customers demand.</p>
                        </div>
                    </div>
                    <!--/Third row-->

                </div>
                <!--/Grid column-->

                <!--Grid column-->
                <div class="col-lg-6 col-md-12">

                    <p class="h5 text-center mb-4">WATCH OUR 1 MIN QUICK START ABOUT SHAZ</p>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item" src="\assets\vedio\about.mp4" frameborder="0"
                            allowfullscreen matTooltip="Click to watch more details About Shaz">
                        </iframe>
                    </div>
                </div>
                <!--/Grid column-->

            </div>
            <!--/Grid row-->
        </section>
        <!--Section: Main features & Quick Start-->

        <hr class="my-5">

        <!--Section: Not enough-->
        <section>

            <h2 class="my-5 h3 text-center">SHAZ INFRASTRUCTURE</h2>

            <!--First row-->
            <div class="row features-small mb-5 mt-3 wow fadeIn">

                <!--First column-->
                <div class="col-md-4">
                    <!--First row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-check-circle fa-2x indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <img src="\assets\image\infrustructure\1.jpg" class="img-home2">
                            <div style="height:15px"></div>
                        </div>
                    </div>
                    <!--/First row-->

                    <!--Second row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-check-circle fa-2x indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <img src="\assets\image\infrustructure\5.jpg" class="img-home2">
                            <div style="height:15px"></div>
                        </div>
                    </div>
                    <!--/Second row-->

                </div>
                <!--/First column-->

                <!--Second column-->
                <div class="col-md-4 mt-2">
                    <!--First row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-check-circle fa-2x indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <img src="\assets\image\infrustructure\2.jpg" class="img-home2">
                            <div style="height:15px"></div>
                        </div>
                    </div>
                    <!--/First row-->

                    <!--Second row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-check-circle fa-2x indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <img src="\assets\image\infrustructure\4.jpeg" class="img-home2">
                            <div style="height:15px"></div>
                        </div>
                    </div>
                    <!--/Second row-->
                </div>
                <!--/Second column-->

                <!--Third column-->
                <div class="col-md-4 mt-2">
                    <!--First row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-check-circle fa-2x indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <img src="\assets\image\infrustructure\3.jpeg" class="img-home2">
                            <div style="height:15px"></div>
                        </div>
                    </div>
                    <!--/First row-->

                    <!--Second row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-check-circle fa-2x indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <img src="\assets\image\infrustructure\7.png" class="img-home2">
                            <div style="height:15px"></div>
                        </div>
                    </div>
                    <!--/Second row-->
                </div>
                <!--/Third column-->

            </div>
            <!--/First row-->

        </section>
    </div>
    <div>

        <!--Footer-->
        <section class="page-footer text-center font-small mt-4 wow fadeIn">
            <div style="text-align: center;">
                <share-buttons [theme]="'circles-dark'"
                    [include]="['facebook','twitter','linkedin','pinterest','reddit','telegram','messenger','whatsapp','line','sms','email']"
                    [url]="['https://www.facebook.com/Shazenterprise','','','','','','','https://wa.me/message/7ZZMCGPBUA4KC1','','']"
                    [title]="['Facebook']" [show]="9" [size]="1" [autoSetMeta]="false">
                </share-buttons>
            </div>
            <!--Copyright-->
            <div class="footer-copyright py-3">
                © 2020-2021 Copyright: <a href="http://shazexport.com"> shazexport.com </a>
            </div>
            <!--/.Copyright-->
        </section>
    </div>
</main>
<!--Main layout-->

<!--/.Footer-->