<!-- Navbar -->
<!--Main layout-->
<main class="contact-image">
    <div class="container">

        <hr class="my-5">

        <!--Section: Not enough-->
        <section>
            <!--First row-->
            <div class="row features-small mb-5 mt-3 wow fadeIn">

                <!--First column-->
                <div class="col-md-4">
                    <!--First row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-check-circle fa-2x indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <h6 class="feature-title">FACTORY ADDRESS</h6>
                            <p class="grey-text">Shaz Enterprise,<br>
                                Dakshin Kashipur, <br>
                                Chandneswar,
                                Bhangar, <br>
                                South 24 pgs,<br>
                                Kolkata - 743330<br>
                                West Bengal, India
                            </p>
                            <div style="height:15px"></div>
                        </div>
                    </div>
                    <!--/First row-->

                    <!--Second row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-check-circle fa-2x indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <h6 class="feature-title">CONNECT WITH US</h6>
                            <p class="grey-text">
                                <mat-icon>contact_phone</mat-icon> (+91) 6295752814,
                                aman@shazexport.com,
                            </p>
                            <p class="grey-text">
                                <mat-icon>contact_phone</mat-icon> (+91) 9051564159,<br>
                                haphijul@shazexport.com
                            </p>

                            <div style="height:15px"></div>
                        </div>
                    </div>
                    <!--/Second row-->
                </div>
                <!--/First column-->


                <!--Second column-->
                <div class="col-md-4 flex-center">
                    <a href="https://www.google.co.in/maps/place/Shaz/@22.439126,87.3502436,8z/data=!4m8!1m2!2m1!1sshaz!3m4!1s0x3a020e0eedf0d7d7:0xf4c60c1434f6aa19!8m2!3d22.4388655!4d88.5631547?hl=en&authuser=0"
                        target="_blank"><img src="\assets\image\contact2.jpg" class="z-depth-0 img-fluid"></a>
                </div>
                <!--/Second column-->


                <!--Third column-->
                <div class="col-md-4 mt-2">
                    <!--First row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-check-circle fa-2x indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <h6 class="feature-title">CONNECT IN WHATSUP</h6>
                            <a href="https://wa.me/message/7ZZMCGPBUA4KC1" target="_blank">
                                <img src="\assets\image\whatsApp.png" class="img-home">
                            </a>
                            <div style="height:15px"></div>
                        </div>
                    </div>
                    <!--/First row-->

                    <!--Second row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-check-circle fa-2x indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <h6 class="feature-title">FOLLOW US ON INSTAGRAM</h6>
                            <a href="https://www.instagram.com/shaz_enterprise/" target="_blank">
                                <img src="\assets\image\insta.png" class="img-home">
                            </a>
                            <div style="height:15px"></div>
                        </div>
                    </div>
                    <!--/Second row-->

                    <!--Third row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-check-circle fa-2x indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <h6 class="feature-title">FOLLOW US ON FACEBOOK</h6>
                            <a href="https://www.facebook.com/Shazenterprise" target="_blank">
                                <img src="\assets\image\facebook.png" class="img-home">
                            </a>
                            <div style="height:15px"></div>
                        </div>
                    </div>
                    <!--/Third row-->
                </div>
                <!--/Third column-->

            </div>
            <!--/First row-->

        </section>
        <!--Footer-->
        <section class="page-footer text-center font-small mt-4 wow fadeIn">
            <div style="text-align: center;">
                <share-buttons [theme]="'circles-dark'"
                    [include]="['facebook','twitter','linkedin','pinterest','reddit','telegram','messenger','whatsapp','line','sms','email']"
                    [url]="['https://www.facebook.com/Shazenterprise','','','','','','','https://wa.me/message/7ZZMCGPBUA4KC1','','']"
                    [title]="['Facebook']" [show]="9" [size]="1" [autoSetMeta]="false">
                </share-buttons>
            </div>
            <!--Copyright-->
            <div class="footer-copyright py-3">
                © 2020-2021 Copyright: <a href="http://shazexport.com"> shazexport.com </a>
            </div>
            <!--/.Copyright-->
        </section>
    </div>

</main>
<!--Main layout-->

<!--/.Footer-->