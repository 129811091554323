<!-- Navbar -->
<nav class="navbar fixed-top navbar-expand-lg navbar-dark scrolling-navbar">
  <div class="container">

    <!-- Brand -->
    <a class="navbar-brand" target="_blank">
      <strong>SHAZ ENTERPRISE</strong>
    </a>

    <!-- Collapse -->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- Links -->
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <!-- Left -->
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" href="#">HOME
            <span class="sr-only">(current)</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/collection" routerLinkActive="active">COLLECTION</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/contactUs" routerLinkActive="active">CONTACT</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/aboutUs" routerLinkActive="active">ABOUT US</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<router-outlet></router-outlet>