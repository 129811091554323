<!-- Navbar -->
<!--Main layout-->
<main class="about-image">
    <div class="container">

        <hr class="my-5">
        <!--Section: Main features & Quick Start-->
        <section>
            <!--Grid row-->
            <div class="row wow fadeIn">

                <!--Grid column-->
                <div class="col-lg-6 col-md-12 px-4">

                    <!--First row-->
                    <div class="row">
                        <div class="col-1 mr-3">
                            <i class="fas fa-code fa-2x indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <h5 class="feature-title">ABOUT SHAZ</h5>
                            <p class="grey-text">Shaz Enterprise founded in 2010, We have been
                                specialized in designing and manufacturing all kinds of genuine leather bags, wallets
                                and accessories for over 10 years.
                            </p>
                        </div>
                    </div>
                    <!--/First row-->
                    <!--
                    <div style="height:30px"></div> -->

                    <!--Second row-->
                    <!--       <div class="row">
                        <div class="col-1 mr-3">
                            <i class="fas fa-book fa-2x blue-text"></i>
                        </div>
                        <div class="col-10">
                            <h5 class="feature-title">INFRASTRUCTURE</h5>
                            <p class="grey-text">Our Shaz provides for best lighting, natural or artificial where
                                workers are working or passing through. We are cleaning of inner and outer surface is
                                provided for all glazed windows and skylights used for the lighting of the workrooms.
                            </p>
                        </div>
                    </div> -->
                    <!--/Second row-->
                </div>
                <!--/Grid column-->

                <!--Grid column-->
                <div class="col-lg-6 col-md-12">

                    <!--First row-->
                    <div class="row">
                        <div class="col-1 mr-3">
                            <i class="fas fa-code fa-2x indigo-text"></i>
                        </div>
                        <div class="col-10">
                            <h5 class="feature-title">INFRASTRUCTURE</h5>
                            <p class="grey-text">Our Shaz provides for best lighting, natural or artificial where
                                workers are working or passing through. We are cleaning of inner and outer surface is
                                provided for all glazed windows and skylights used for the lighting of the workrooms.
                            </p>
                        </div>
                    </div>
                    <!--/First row-->

                    <div style="height:30px"></div>

                    <!--Second row-->
                    <!--    <div class="row">
                        <div class="col-1 mr-3">
                            <i class="fas fa-book fa-2x blue-text"></i>
                        </div>
                        <div class="col-10">
                            <h5 class="feature-title">Company's PAN</h5>
                            <p class="grey-text">ACJPI7473A
                            </p>
                        </div>
                    </div> -->
                    <!--/Second row-->

                </div>
                <!--/Grid column-->

            </div>
            <!--/Grid row-->
        </section>
        <!--Section: Main features & Quick Start-->



        <hr class="mb-5">

        <!--Section: More-->
        <section class="about-image">

            <h2 class="my-5 h3 text-center">COMPANY INFORMATION</h2>

            <!--First row-->
            <div class="row features-small mt-5 wow fadeIn">

                <!--Grid column-->
                <div class="col-xl-3 col-lg-6">
                    <!--Grid row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fab fa-firefox fa-2x mb-1 indigo-text" aria-hidden="true"></i>
                        </div>
                        <div class="col-10 mb-2 pl-3">
                            <h5 class="feature-title">CEO</h5>
                            <p class="grey-text">Mr. Haphijul<br>
                                haphijul@shazexport.com<br>
                                (+91) 9051564159
                            </p>
                        </div>
                    </div>
                    <!--/Grid row-->
                </div>
                <!--/Grid column-->

                <!--Grid column-->
                <div class="col-xl-3 col-lg-6">
                    <!--Grid row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-level-up-alt fa-2x mb-1 indigo-text" aria-hidden="true"></i>
                        </div>
                        <div class="col-10 mb-2">
                            <h5 class="feature-title">DIRECTOR</h5>
                            <p class="grey-text">Mrs. Sakila <br>
                                sakila@shazexport.com<br>
                                (+91) 9732313986
                            </p>
                        </div>
                    </div>
                    <!--/Grid row-->
                </div>
                <!--/Grid column-->

                <!--Grid column-->
                <div class="col-xl-3 col-lg-6">
                    <!--Grid row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="far fa-comments fa-2x mb-1 indigo-text" aria-hidden="true"></i>
                        </div>
                        <div class="col-10 mb-2">
                            <h5 class="feature-title">MARKETING MANAGER</h5>
                            <p class="grey-text">Mr. Aman<br>
                                aman@shazexport.com<br>
                                (+91) 6295752814
                            </p>
                        </div>
                    </div>
                    <!--/Grid row-->
                </div>
                <!--/Grid column-->

                <!--Grid column-->
                <div class="col-xl-3 col-lg-6">
                    <!--Grid row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-code fa-2x mb-1 indigo-text" aria-hidden="true"></i>
                        </div>
                        <div class="col-10 mb-2">
                            <h5 class="feature-title">Company's GST</h5>
                            <p class="grey-text">19ACJPI7473A1ZK</p>
                        </div>
                    </div>
                    <!--/Grid row-->
                </div>
                <!--/Grid column-->

            </div>
            <!--/First row-->

            <!--Second row-->
            <div class="row features-small mt-4 wow fadeIn">

                <!--Grid column-->
                <div class="col-xl-3 col-lg-6">
                    <!--Grid row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-cubes fa-2x mb-1 indigo-text" aria-hidden="true"></i>
                        </div>
                        <div class="col-10 mb-2">
                            <h5 class="feature-title">Company's PAN</h5>
                            <p class="grey-text">ACJPI7473A
                            </p>
                        </div>
                    </div>
                    <!--/Grid row-->
                </div>
                <!--/Grid column-->

                <!--Grid column-->
                <div class="col-xl-3 col-lg-6">
                    <!--Grid row-->
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-question fa-2x mb-1 indigo-text" aria-hidden="true"></i>
                        </div>
                        <div class="col-10 mb-2">
                            <h5 class="feature-title">Company's Importer Exporter Code (IEC)</h5>
                            <p class="grey-text">ACJPI7473A</p>
                        </div>
                    </div>
                    <!--/Grid row-->
                </div>
                <!--/Grid column-->
            </div>
            <!--/Second row-->

        </section>
        <!--Footer-->
        <section class="page-footer text-center font-small mt-4 wow fadeIn">
            <div style="text-align: center;">
                <share-buttons [theme]="'circles-dark'"
                    [include]="['facebook','twitter','linkedin','pinterest','reddit','telegram','messenger','whatsapp','line','sms','email']"
                    [url]="['https://www.facebook.com/Shazenterprise','','','','','','','https://wa.me/message/7ZZMCGPBUA4KC1','','']"
                    [title]="['Facebook']" [show]="9" [size]="1" [autoSetMeta]="false">
                </share-buttons>
            </div>
            <!--Copyright-->
            <div class="footer-copyright py-3">
                © 2020-2021 Copyright: <a href="http://shazexport.com"> shazexport.com </a>
            </div>
            <!--/.Copyright-->
        </section>

    </div>
</main>
<!--Main layout-->

<!--/.Footer-->