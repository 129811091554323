<!-- Navbar -->
<!--Main layout-->
<main class="back-image">
  <div class="container">
    <hr class="my-5" />

    <!--Section: Not enough-->
    <section>
      <!--First row-->
      <div class="row features-small mb-5 mt-3 wow fadeIn">
        <h3>
          Please contact us for sample copy using Email or WhatsApp. We will
          provide you all details regarding our products. Thank you.
        </h3>
      </div>
    </section>
  </div>
</main>
<!--Main layout-->
